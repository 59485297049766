.onboarding-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: var(--bs-body-bg);
}

.onboarding-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--bs-body-bg);
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.onboarding-container .progress {
  height: 0.5rem;
  background-color: var(--bs-gray-200);
  border-radius: 1rem;
  overflow: hidden;
}

.onboarding-container .progress-bar {
  background-color: var(--bs-primary);
  transition: width 0.4s ease;
}

.step-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-control-lg {
  padding: 1rem;
  font-size: 1.1rem;
}

.btn-lg {
  padding: 1rem 2rem;
}

/* Dark mode adjustments */
[data-bs-theme="dark"] .onboarding-container {
  background: var(--bs-dark);
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
}

.review-step .review-item {
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: var(--bs-gray-100);
  margin-bottom: 0.5rem;
}

[data-bs-theme="dark"] .review-step .review-item {
  background: var(--bs-gray-800);
}

.review-step .card {
  border: none;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

[data-bs-theme="dark"] .review-step .card {
  background: var(--bs-gray-900);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .onboarding-container {
    padding: 1rem;
    margin: 0.5rem;
    max-width: 100%;
  }

  .onboarding-container .form-control-lg,
  .onboarding-container .form-select-lg {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .onboarding-container .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
} 