.image-upload {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        text-align: center;



        h2 {
          color: #333;
          font-size: 1.5rem;
          margin-bottom: 20px;
        }
      
        input[type='file'] {
          display: none; 
        }
      
        label {
          display: inline-block;
          padding: 15px 25px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1.1rem;
          font-weight: bold;
          transition: background-color 0.3s ease;
          touch-action: manipulation;

          &:hover {
            background-color: #0056b3;
          }
        }
      
        
      
          h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
        }
      
        button {
          margin-top: 20px;
          padding: 10px 20px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
          transition: background-color 0.3s ease;
      
          &:hover {
            background-color: #218838;
          }
      
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }

img{
        max-width: 100%;
}